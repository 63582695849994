<ng-container *ngIf="form">
    <ng-container *ngIf="form.original_type != 'calc';else isCalcField;">
        <ng-container *ngIf="['text','email','url'].indexOf(form['type'])>=0 && field['Field']!='password'">
            <input [(ngModel)]="value" (change)="onChange(null,null,$event)" [maxLength]="form['max_len']?form['max_len']:10000000" [minLength]="form['min_len']?form['min_len']:0"
                   placeholder="{{form.placeholder}}"
                   type="text" name="{{field['Field']}}" [id]="getId()" class="form-control">
        </ng-container>
        <!--        [mentionConfig]="{triggerChar:'{',items:mentionItems(),mentionSelect:mentionSelect}"-->

        <div *ngIf="form['type'] == 'number'" class="input-group">
            <span *ngIf="form.num_unit && form.num_unit_order=='first'" class="input-group-addon">{{form.num_unit}}</span>
            <input OnlyNumber (change)="onChangeNum($event)" [(ngModel)]="view_value" [object]="value" placeholder="{{form.placeholder}}" min="{{form.getMinNumber()}}"
                   max="{{form.getMaxNumber()}}"
                   type="text"
                   inputmode="decimal"
                   step="{{ count_step() }}" name="{{field['Field']}}"
                   [ngClass]="{'width80':!!form.num_unit}"
                   [id]="getId()" class="form-control input-number">


            <span *ngIf="form.num_unit && form.num_unit_order=='end'" class="input-group-addon"> {{form.num_unit}}</span>
        </div>
        <div *ngIf="field['Field'] == 'password'">
            <input (change)="onChange()" [(ngModel)]="value" autocomplete="new-password" type="password" name="{{field['Field']}}" class="form-control" placeholder="パスワード">
            <input (change)="onChange(field.Field+'_conf',password_conf_value||' ')" autocomplete="new-password" [(ngModel)]="password_conf_value" type="password" name="{{field['Field']}}_conf"
                   class="form-control" placeholder="パスワード確認">
                    <button (click)="onCreatePassword()" type="button" *ngIf="is_add"
                        class="btn btn-sm btn-default p-1 pl-2 pr-2">
                        パスワードを自動生成
                    </button>
        </div>
        <div *ngIf="form['type']=='password'">
            <input (change)="onChange()" [(ngModel)]="value" autocomplete="new-password" type="password" name="{{field['Field']}}" class="form-control" placeholder="パスワード">
        </div>
        <div *ngIf="!is_add && form['type']=='calc' && form['is_calc_auto_reload_off']" class="checkbox">
            {{value}}
            <br>
            <label>
                <input (change)="onChange(field.Field+'_update',is_update_calc)" [(ngModel)]="is_update_calc" type="checkbox" name="{{field['Field']}}_update" value="update">
                値を更新する
            </label>
        </div>
        <ng-template [ngIf]="form['type']=='color'">
            <input [(ngModel)]="value" (change)="onChange()"
                   type="text" name="{{field['Field']}}" class="form-control">
            <input type="color" [(ngModel)]="value"
                   name="{{field['Field']}}">
        </ng-template>
        <div *ngIf="form['type'] == 'image' && value!=null && value!=''" style="justify-content: center; ;">
            <img class="admin-forms__image mb-2" [src]="thumbnail_file_info.thumbnail_url"
                 *ngIf="thumbnail_file_info!=undefined" (click)="openImg()">
            <img class="admin-forms__image mb-2" [src]="icon_image_url" *ngIf="icon_image_url" (click)="openImg()">
            <div *ngIf="form.display_image_filename">
                <a class="d-none d-lg-inline" *ngIf="thumbnail_file_info!=null" href="javascript:void(0)"
                    (click)="download_file(thumbnail_file_info.url,thumbnail_file_info.name)">
                    {{thumbnail_file_info.name}}
                </a>
                <div *ngIf="thumbnail_file_info && isDownloading(thumbnail_file_info.url)">
                    <div class="loader_small">Loading...</div>
                </div>
            </div>
            <br>

            <button *ngIf="!form.required && !isChild()" (click)="openDeleteModal()" class="button  btn-danger btn btn-sm mb-2">
                画像を削除
            </button>
        </div>
        <div
            *ngIf="form['type'] == 'file' && value!=null && value!='' && !isObject(value)">

            <div style="margin-bottom:20px;">
                <div *ngIf="!is_string(view_value)">
                     <a class="d-none d-lg-inline" *ngIf="view_value!=null && !is_string(view_value)"
                         href="javascript:void(0)"
                        (click)="download_file(view_value['url'],false,view_value['name'])">{{ view_value['name'] }}</a>
                         <div *ngIf="view_value && isDownloading(view_value.url)">
                             <div class="loader_small">Loading...</div>
                         </div>
                </div>
                <div *ngIf="is_string(view_value)">{{view_value}}</div>
            </div>
        </div>
        <div *ngIf="form['type'] == 'file' && value!=null && value!=''">
            <button *ngIf="!form.required && !isChild()" (click)="openDeleteModal()"
                class="delete-btn btn-danger btn-xs mb-2">ファイルを削除
            </button>
        </div>
        <ng-container *ngIf="(form['type'] == 'image' || form['type'] == 'file') && !disable_edit">
            <div class="fileStyle" [appModuleDragAndDrop]="true" (files)="fileChange(data,$event)" (click)="file.click()">
                <div *ngIf="value && value.name!=undefined; then thenBlock else elseBlock"></div>
                <ng-template #thenBlock>
                    <div [hidden]="!isimagecheck">
                        <img id="{{value.id_generate}}" [src]="value.url" class="preview_thumbnail"/>
                    </div>
                    <p class="mediumFont text-primary font-weight-bold">[{{value.name}}]が選択済み</p></ng-template>
                <ng-template #elseBlock><i class="fa fa-cloud-upload fileUploadIcon"></i>
                    <p *ngIf="!form['is_multi_value_mode']" class='mediumFont  mb-0'>ここにファイルをドロップ</p>
                    <p *ngIf="form['is_multi_value_mode']" class='mediumFont  mb-0'>複数ファイル選択可能</p>
                    <small>※うまくアップロード出来ない場合は、ここをクリックして直接ファイルを選択して下さい。</small>
                </ng-template>

                <input hidden #file
                       (change)="fileChange(data,$event)" [id]="getId()" name="{{field['Field']}}"
                       type="file">

            </div>
        </ng-container>
        <div *ngIf="form['type'] === 'year_month'" class="year_month">
            <input class="form-control not-full year yearInput-{{field['Field']}}" type="text" maxlength="4" max="2300" min="1900" [(ngModel)]="year" (input)="onYearMonthInput($event)">&nbsp;年
            <input class="form-control not-full month monthInput-{{field['Field']}}" type="text" maxlength="2" max="12" min="1" [(ngModel)]="month" (input)="onYearMonthInput($event)">&nbsp;月
        </div>
        <div
            *ngIf="form['type'] == 'datetime' || form['type'] == 'date' || (form['type'] == 'time' && isOneMinuteTimeField(form))">

            <div class="input-group date-input-group">
                <input class="datetime dateInput-{{field['Field']}}" type="text"
                       [owlDateTime]="owl_datetime"
                       name="{{field['Field']}}" [(ngModel)]="date_value" (ngModelChange)="datetimeValueChanged()"
                       (input)="onInput($event)" (blur)="onBlur($event)"
                >
                <span class="input-group-addon" style="cursor: pointer" *ngIf="form['type'] != 'time'" [owlDateTimeTrigger]="owl_datetime">
                <i class="fa fa-calendar"></i>
                </span>
                <span class="input-group-addon" style="cursor: pointer" *ngIf="form['type'] == 'time'" [owlDateTimeTrigger]="owl_datetime">
                <i class="fa fa-clock-o"></i>
                </span>
                <owl-date-time
                    (afterPickerOpen)="onOpenDtPicker()"
                    #owl_datetime
                    [pickerType]="form['type'] == 'datetime'? 'both': form['type'] == 'date'? 'calendar': 'timer'">
                </owl-date-time>
                <button class="btn btn-sm btn-default ml-2 now-btn" style="padding-left:7px;padding-right:7px;" (click)="setCurrentDateTime($event)">現在</button>
            </div>
            <ng-container *ngIf="false">
                <br style="clear:both">
                <div class="col-md-12 mt-2">
                    <label>
                        <input (change)="onChange()" type="checkbox" [value]="true" class="pr-2" (click)="onClickCurrentDatetime($event)"> &nbsp;現在日時
                    </label>
                </div>
            </ng-container>
        </div>
        <!--20240529 Kanazawa 追加 15分間隔以上の時間の設定 -->
        <ng-container *ngIf="form['type'] == 'time' && form.custom_field && form.custom_field['minutes_interval'] > 1">
            <select [(ngModel)]="value" class="form-control" (change)="onChange()">
                <option *ngFor="let time of this.minutes_by_interval[form.custom_field['minutes_interval']]" value="{{time}}:00">
                    {{time}}
                </option>
            </select>
        </ng-container>
        <div *ngIf="form['type'] == 'textarea'">
        <textarea (change)="onChange(null,null,$event)" [mention]="auto_complete_values" [mentionConfig]="{triggerChar:'{',items:name,mentionSelect:mentionSelect}"
                  [(ngModel)]="value"
                  placeholder="{{form.placeholder}}"
                  class="form-control" appTextareaAutoresize
                  data-min-height="150"
                  #textarea
                  (input)="resize(textarea)"
                  ></textarea>
        </div>
        <div style="" *ngIf="form['type'] == 'richtext'" [froalaEditor]="_share.getFroalaOption('full',this.table_info.table, this.IS_PUBLIC_FORM ? 'iframe-form' : '.app-body')"
             [(froalaModel)]="value" (froalaModelChange)="onChangeRichText($event)"></div>
        <ng-container
            *ngIf="['select','select_other_table','table','fields','notify_target_fields','email_fields','file_fields','table_filter'].indexOf(form['type']) >= 0 && option_items && !this.loading">
            <div class="input-group">

                <ng-select
                    *ngIf="isUsePulldownIngoreEmbedAndPublic();else enableSearchText"
                    class="custom form-control"
                    [id]="getId()"
                    [required]="form.required"
                    [items]="option_items"
                    [loading]="false"
                    loadingText='Loading...'
                    appendTo="body"
                    [multiple]="form.isFieldsMulti()"
                    [searchWhileComposing]="false"
                    [virtualScroll]="true"
                    bindLabel="label"
                    bindValue="value"
                    [(ngModel)]="value"
                    (change)="onChangeOtherSelectValue($event)"
                    (open)="openNgSelect()"
                    (focusout)="onSelectFocusOut($event)"
                    [clearable]="!form.required"
                    [disabled]="!is_all_related_condition_value_selected"
                    [placeholder]="selectPlaceHolder"
                    (keyup)="onChangeSelect($event)"

                ></ng-select>


                <ng-template #enableSearchText>
                    <input *ngIf="form.not_display_pulldown" (change)="onChangeSelect($event)" [(ngModel)]="lookupSearchValue" type="text" name="{{field['Field']}}" class="form-control">
                </ng-template>

                <span
                    *ngIf="form.original_type === 'select_other_table' && !IS_PUBLIC_FORM && !IS_EMBED_MODE && form.show_lookup_modal && !isEditMode"
                    class="input-group-addon pointer" (click)="is_all_related_condition_value_selected ? openLookupModal() : null">
                <i class="fa fa-search "></i>
                </span>

            </div>
            <ng-container *ngIf="form.not_display_pulldown && !isEditMode">
                <small *ngIf="value">
                    選択済み:{{view_value}}
                </small>

            </ng-container>
        </ng-container>

        <ng-container *ngIf="form.type === 'multi-select'">
            <ng-select
                ng-required="true"
                class="custom form-control"
                appendTo="body"
                [id]="getId()"
                [items]="admin_notification"
                [multiple]="true"
                [searchWhileComposing]="false"
                [virtualScroll]="true"
                bindLabel="label"
                bindValue="value"
                [(ngModel)]="value"
                (change)="onChange()"
            ></ng-select>
        </ng-container>
        <ng-template [ngIf]="form.type == 'boolean'">
            <div class="checkbox">
                <label>
                    <label class="switch switch-text switch-pill switch-primary" *ngIf="form.use_switch;else notUseSwitch;">
                        <input type="checkbox" class="switch-input" [attr.disabled]="form.disable_switch == 'true' ? true : null" (change)="onBooleanChange()" [checked]="value=='true'">
                        <span class="switch-label" data-on="On" data-off="Off"></span>
                        <span class="switch-handle"></span>
                    </label>
                    <ng-template #notUseSwitch>
                        <input type="checkbox" class="pg-checkbox" [checked]="value=='true'" (change)="onBooleanChange()" [id]="getId()" (click)="onClickBoolean($event)">
                        <label class="checkbox-custom" [for]="getId()">
                            <ng-container *ngIf="form.option_label;else NO_OPTION_LABEL">
                                {{form.option_label}}
                            </ng-container>
                            <ng-template #NO_OPTION_LABEL>
                                {{form.boolean_text}}
                            </ng-template>
                        </label>

                    </ng-template>
                </label>
            </div>
        </ng-template>
        <ng-template [ngIf]="(form['type'] == 'checkbox')">
            <!-- ngModelはhiddenに紐付け、checkboxでhiddenのvalueを変える -->
            <ng-container *ngIf="isMultiSelect(form);else notMultiSelect">
                <ng-select
                    class="custom form-control"
                    appendTo="body"
                    [id]="getId()"
                    [items]="option_items"
                    [multiple]="true"
                    [searchWhileComposing]="false"
                    [virtualScroll]="true"
                    bindLabel="label"
                    bindValue="label"
                    [(ngModel)]="value"
                    (change)="onChange()"
                ></ng-select>
            </ng-container>
            <ng-template #notMultiSelect>
                <div *ngFor="let option of option_items" class="checkbox layout-{{this.form['_layout'] ?? this.form['_custom_field']['layout']}}">
                    <input type="checkbox" class="pg-checkbox" [checked]="isCheck(i,option.value)" id="{{unique_input_id}}_{{field['Field']}}_{{option.value}}"
                           value="{{option.value}}" name="{{unique_input_id}}_{{field['Field']}}"
                           (change)="checkboxChange(field['Field'],unique_input_id+'_'+field['Field'])">
                    <label class="checkbox-custom" for="{{unique_input_id}}_{{field['Field']}}_{{option.value}}">
                        {{option.label}}
                    </label>
                </div>
            </ng-template>
        </ng-template>
        <ng-template [ngIf]="form['type'] == 'radio'">
            <div *ngFor="let option of option_items" class="radio layout-{{this.form['_layout'] ?? this.form['_custom_field']['layout']}}">
                <input (change)="onChange()" [(ngModel)]="value" type="radio" class="pg-radio" id="{{field_for_radio}}_{{field['Field']}}_{{option.value}}"
                       name="{{field_for_radio}}_{{field['Field']}}" value="{{option.value}}">
                <label class="radio-custom" for="{{field_for_radio}}_{{field['Field']}}_{{option.value}}">
                    {{option.label}}
                </label>
            </div>
            <div *ngIf="form.field.Field == 'two_factor_method' && value == 'qr'">
             <div class="col-12 mb-3">
                <img [src]="'data:image/png;base64,' + qrCodeImage" alt="QR Code" style="width: 200px; height: auto;" />
                <p>このQRコードをAuthenticatorアプリでスキャンしてください。</p>
            </div>
            </div>
        </ng-template>
        <ng-template [ngIf]="(form.type == 'grant_group')">
            <dynamic-data-view [is_view_mode]="true" [dataType]="'grant_group'" [grantGroupData]="grantGroupData" [loadGrantGroupAuto]="false"></dynamic-data-view>
            <button class="btn btn-success btn-sm mt-2" (click)="grantGroupModal.show()" type="button">権限ユーザー/組織 選択</button>


            <div bsModal #grantGroupModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                 aria-hidden="true">
                <div class="modal-dialog modal-default" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">権限グループ選択</h4>
                            <button type="button" class="close" (click)="grantGroupModal.hide()" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body m-2">
                            <edit-component [_mode]="!value?'add':'edit'" [IS_EMBED_MODE]="true" [_id]="!value?null:value" [_table]="'grant_group'" (onSubmit)="onInputGrantGroup($event)"
                                            (onCancel)="grantGroupModal.hide()" [is_add_new_button_select_other_table]="false" [grantGroupData]="grantGroupData"></edit-component>
                        </div>
                    </div><!-- /.modal-content -->
                </div><!-- /.modal-dialog -->
            </div><!-- /.modal -->


        </ng-template>
        <ng-template [ngIf]="(form.type == 'condition' || form.type =='timing_condition')">
            <div class="" *ngIf="isConditionTableSet(); else tableNotSelected;">
                <button class="btn btn-success btn-sm" (click)="addCondition()"><i class="fa fa-plus mr-1"></i>
                    <ng-container *ngIf="form.type=='condition'">
                        条件追加
                    </ng-container>
                    <ng-container *ngIf="form.type=='timing_condition'">
                        タイミング追加
                    </ng-container>
                </button>
            </div>
            <ng-template #tableNotSelected>
                [テーブルを選択して下さい]
            </ng-template>
            <ng-container *ngIf="conditions">
                <ng-container *ngFor="let condition of conditions.condition_a; let i = index;">
                    <div class="condition-wrap mt-1">
                        <condition-form [id_prefix]="id_prefix +'_' + form.type + '_condindex_' + i + '_'" [condition_type]="form.type" [table]="getConditionTable()"
                                        [default_condition]="condition"
                                        (valueChanged)="onValueChanged($event)"
                                        [index]="i"></condition-form>
                        <div class="buttons">
                            <button class="btn btn-danger pull-left btn-sm btn" (click)="delCondition(i)"><i class="fa fa-minus"></i></button>
                        </div>
                    </div>
                </ng-container>
                <br style="clear: both">
            </ng-container>

        </ng-template>

        <!-- ONLY GRANT!!!! -->
        <ng-template [ngIf]="form['type'] == 'grant'">
            <grant-check [grant_json]="value" [table]="'dataset__'+editComponent.id" (valueChanged)="onChangeGrant($event)"
                         [is_show_table_grant]="this.table_info.table==='dataset_grant'"></grant-check>
        </ng-template>
        <!-- ONLY GRANT END!!!! -->


        <ng-template [ngIf]="form.type == 'noti_type'">
            <input type="hidden" value="{{datasetId(grant_menu_a)}}" id="dataset_id">
            <!--
                <label>
                    <input type="checkbox" value="email"
                           id="email"
                           name="{{field['Field']}}_dataset"
                           (change)="checkboxChange(field['Field'],field['Field']+'_dataset')"
                           [checked]="isCheck(value,'email')"
                           >
                    Email
                </label>
                <br>
                <label>
                    <input type="checkbox" value="sms"
                           id="sms"
                           name="{{field['Field']}}_dataset"
                           (change)="checkboxChange(field['Field'],field['Field']+'_dataset')"
                        [checked]="isCheckGrant('sms')"
                           >
                    SMS
                </label>
                <br>
            <label>
                <input type="checkbox" value="created_user"
                       name="{{field['Field']}}_dataset"
                       (change)="checkboxChange(field['Field'],field['Field']+'_dataset')"
                       [checked]="isCheck(value,'created_user')"
                >
                作成者
            </label>
            <br>
            -->
            <label>
                <input type="checkbox" value="slack"
                       name="{{field['Field']}}_dataset"
                       id="slack"
                       (change)="checkboxChange(field['Field'],field['Field']+'_dataset')"
                       [checked]="isCheck(value,'slack')"
                >
                Slack
            </label>
            <br>
            <label>
                <input type="checkbox" value="webhook_url"
                       id="webhook_url"
                       name="{{field['Field']}}_dataset"
                       (change)="checkboxChange(field['Field'],field['Field']+'_dataset')"
                       [checked]="isCheck(value,'webhook_url')"
                >
                Webhook
            </label>
        </ng-template>
        <div class="grant_patterns ml-2" *ngIf="table_info && (table_info.table==='dataset_group') && this.form.field_name === 'group_grant_type'">
            <div class="flex">
                <div class="col-sm-3">
                    <button class="quick-button" [ngClass]="{'active':value=='everyone'}" (click)="changeGrantType('everyone')">
                        <i class="fa fa-group"></i>
                        <p>全員閲覧可能</p>
                    </button>
                </div>

                <div class="col-sm-3">
                    <button class="quick-button" [ngClass]="{'active':value=='custom'}" (click)="changeGrantType('custom')" [disabled]="is_add">
                        <i class="fa fa-gear"></i>
                        <p>詳細設定</p>

                    </button>
                </div>
            </div>
            <div class="mt-4 pl-2" *ngIf="isDatasetEdit() && is_add">
                ※詳細設定は一度保存後に設定可能です。
            </div>
        </div>
        <div class="grant_patterns ml-2" *ngIf="table_info && (isDatasetEdit()  || table_info.table==='dataset_group') && this.form.field_name === 'grant_type'">
            <div class="flex">
                <div class="col-sm-3" *ngIf="table_info.table === 'dataset_group'">
                    <button class="quick-button active" [ngClass]="{'active':value=='none'}" (click)="changeGrantType('none')">
                        <i class="fa fa-ban"></i>
                        <p>無し</p>
                    </button>
                </div>
                <div class="col-sm-3" *ngIf="isDatasetEdit()">
                    <button class="quick-button active" [ngClass]="{'active':value=='me'}" (click)="changeGrantType('me')">
                        <i class="fa fa-user"></i>
                        <p>作成者のみ</p>
                    </button>
                </div>
                <div class="col-sm-3">
                    <button class="quick-button" [ngClass]="{'active':value=='everyone'}" (click)="changeGrantType('everyone')">
                        <i class="fa fa-group"></i>
                        <p>全員編集可能</p>
                    </button>
                </div>

                <div class="col-sm-3">
                    <button class="quick-button" [ngClass]="{'active':value=='custom'}" (click)="changeGrantType('custom')" [disabled]="is_add">
                        <i class="fa fa-gear"></i>
                        <p>詳細設定</p>

                    </button>
                </div>
            </div>
            <div class="mt-4 pl-2" *ngIf="isDatasetEdit() && is_add">
                ※詳細設定は一度保存後に設定可能です。
            </div>
        </div>
    </ng-container>
    <ng-template #isCalcField>
        <input class="form-control" disabled [value]="view_value ?? null">
    </ng-template>
</ng-container>

<ng-container *ngIf="form && form.button_label">
    <button class="btn btn-sm btn-success pull-right mt-1" (click)="call_func(form.button_function)" [ladda]="sending_custom_button">{{form.button_label}}</button>
</ng-container>


<div bsModal #deleteModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></h4>
                <button type="button" class="close" (click)="deleteModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>本当に削除してもよろしいですか？</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">キャンセル</button>
                <button type="button" class="btn btn-danger" (click)="deleteImage()">削除する</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #verifyModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">SMTP送信テスト</h4>
                <button type="button" class="close" (click)="verifyModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>送信テストするメールアドレスを入力して下さい</p>
                <input class="form-control" type="text" [(ngModel)]="verify_smtp_email">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="verifyModal.hide()">キャンセル</button>
                <button type="button" class="btn modal-default ml-2" (click)="this.verifySmtp()">送信</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->


<div bsModal #testMailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">送信テスト</h4>
                <button type="button" class="close" (click)="testMailModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>送信テストするメールアドレスを入力して下さい</p>
                <input class="form-control" type="text" [(ngModel)]="verify_smtp_email">
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="testMailModal.hide()">キャンセル</button>
                <button type="button" class="btn modal-default ml-2" (click)="this.sendTestMail()">送信</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

